import React from "react"
import "bootstrap/dist/css/bootstrap.css"
import "./src/styles/bootstrap.scss"
import "./src/styles/style.css"
import "./src/styles/responsive.css"
import "./src/styles/anim.css"

import {CartProvider} from "./src/context/CartContext"

export const wrapRootElement = ({ element }) => (
  <CartProvider>{element}</CartProvider>
)