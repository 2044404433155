import React, {createContext, useState} from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { YMInitializer } from 'react-yandex-metrika';

const CartContext = createContext({  
  cartItems: null,
  cartCount: 0,
  addToCart: () => {},
  deleteFromCart: () => {},
  clearCart: () => {},
  getProductList: () => {},
  changeQTY: () => {}
})

const CartProvider = ({children}) => {
  let cartLocal = localStorage.getItem("cart")
  if(cartLocal !== null) {
    cartLocal = JSON.parse(cartLocal)
    if(Date.now() > (cartLocal.id + 150000000)) {
      localStorage.clear()
      cartLocal = {id: Date.now(), count: 0, products:[]}
    } 
  } else cartLocal = {id: Date.now(), count: 0, products:[]}
  
  if(cartLocal.products.length < 1) cartLocal.count = 0
  const [cartCount, setCartCount] = useState(cartLocal.count)
  const [cartItems, setCartItems] = useState(cartLocal)
  
  const changeQTY = (unicId, quantity) => {
    let indexOfProductCart = cartItems.products.findIndex(product => product.id === unicId)
    if(indexOfProductCart >= 0) {
      cartItems.products[indexOfProductCart].qty = cartItems.products[indexOfProductCart].qty + quantity
      writeLocalStorage(quantity)    
    } 
  }

  const addToCart = (productId, quantity, featureIndex, varesIndex) => {
    let unicProductID = productId + "-" + featureIndex + "-" + varesIndex
    
    let indexOfProductCart = cartItems.products.findIndex(product => product.id === unicProductID)
    if(indexOfProductCart < 0){
      let newCartProduct = {id : unicProductID, productID: productId, qty: quantity, feature: featureIndex, vares: varesIndex}
      cartItems.products.push(newCartProduct)
    } 
    else cartItems.products[indexOfProductCart].qty = cartItems.products[indexOfProductCart].qty + quantity

    writeLocalStorage(quantity)
  }

  const deleteFromCart = (unicId) => {
    let indexOfProductCart = cartItems.products.findIndex(product => product.id === unicId)
    if(indexOfProductCart !== -1) {
      let qty = cartItems.products[indexOfProductCart].qty
      cartItems.products.splice(indexOfProductCart, 1)
      writeLocalStorage(-qty)
    }
  }
  
  const clearCart = () => {
    cartItems.count = 0
    cartItems.products = []
    writeLocalStorage(0)
  }

  const writeLocalStorage = (qty) => {
    cartItems.id = Date.now()
    cartItems.count = cartItems.count + qty
    setCartCount(cartItems.count) 
    setCartItems(cartItems)
    localStorage.setItem( 'cart', JSON.stringify(cartItems))
  }

  const prodJSON = useStaticQuery(graphql`
      {
        allProductsJson {
            nodes {
                id
                name
                price
                variants {
                    varesi
                }
                feature {
                    abbr
                    varesi {
                        value
                        price
                    }                
                }
                images {
                  childImageSharp {
                    fluid(maxWidth: 100, maxHeight: 100, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
            }
        }
      }`).allProductsJson.nodes

  const getProductList = () => {
    let productList = null 
    let cartTotal = 0
    if(cartItems.products.length > 0) { 
      productList = cartItems.products.map( (product) => {
          
          let prodInfo = prodJSON.find(item => item.id === product.productID)

          if (prodInfo !== null) {
            try {
              let productFull = {
                id : product.id,
                name : prodInfo.name,
                price : product.feature !== null ? prodInfo.feature.varesi[product.feature].price : prodInfo.price,
                vares : prodInfo.variants !== null ? prodInfo.variants.varesi[product.vares] : null,
                feature : prodInfo.feature !== null ? prodInfo.feature.varesi[product.feature].value : null,
                abbr : prodInfo.feature !== null ? prodInfo.feature.abbr : null,
                qty : product.qty,
                images: prodInfo.images
              }

              cartTotal = cartTotal + productFull.price * productFull.qty

              return productFull
            } catch (e) {
              deleteFromCart(product.id)
              return null
            }
              
          } else return null
      })
    }
    return [productList, cartTotal]
  }

  return (
    <CartContext.Provider
      value={{     
        cartCount,
        cartItems,
        addToCart,
        deleteFromCart,
        clearCart,
        getProductList,
        changeQTY
      }}
    >
      {children}
      <YMInitializer accounts={[57459400]} />
    </CartContext.Provider>
  )
}

export default CartContext

export {CartProvider}
