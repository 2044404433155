// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-paseka-jsx": () => import("./../src/pages/paseka.jsx" /* webpackChunkName: "component---src-pages-paseka-jsx" */),
  "component---src-pages-shop-index-jsx": () => import("./../src/pages/shop/index.jsx" /* webpackChunkName: "component---src-pages-shop-index-jsx" */),
  "component---src-pages-shop-med-jsx": () => import("./../src/pages/shop/med.jsx" /* webpackChunkName: "component---src-pages-shop-med-jsx" */),
  "component---src-pages-shop-order-jsx": () => import("./../src/pages/shop/order.jsx" /* webpackChunkName: "component---src-pages-shop-order-jsx" */),
  "component---src-templates-single-blog-jsx": () => import("./../src/templates/single-blog.jsx" /* webpackChunkName: "component---src-templates-single-blog-jsx" */),
  "component---src-templates-single-product-jsx": () => import("./../src/templates/single-product.jsx" /* webpackChunkName: "component---src-templates-single-product-jsx" */)
}

